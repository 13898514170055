const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//FF (1111 D) (1111 D) 11 (3 suits)
	function(tiles = []) {
		allOptions.forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 2}))

				newArr.push(createTiles({type: suitOrder[0], value: offset, amount: 4}))
				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[0]], amount: 1}))

				newArr.push(createTiles({type: suitOrder[1], value: offset, amount: 4}))
				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 1}))

				newArr.push(createTiles({type: suitOrder[2], value: offset, amount: 2}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FFFF (11) 111 111 (11) (3 suits - pairs are same suit, 30 points)
	function(tiles = []) {
		allOptions.forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 4}))

				newArr.push(createTiles({type: suitOrder[0], value: offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[1], value: offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[2], value: offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[0], value: offset, amount: 2}))
			})
		})

		return {
			tiles,
			score: 30,
			concealed: false
		}
	},

	//FF 111 111 111 DDD (3 suits, any dragon, concealed, 30 points)
	function(tiles = []) {
		allOptions.forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 2}))

				newArr.push(createTiles({type: suitOrder[0], value: offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[2], value: offset, amount: 3}))
				newArr.push(createTiles({type: "dragon", value: "any", amount: 3}))
			})
		})

		return {
			tiles,
			score: 30,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "Any Like Numbers"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

