const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations, allSuitArrangementsWithSingleSuits} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//(222 4444) (666 8888) (1 or 2 suits)
	function(tiles = []) {
		allSuitArrangementsWithSingleSuits.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 4}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 3}))
			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 4}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FF 2222 4444 = 6666 OR FF 2222 + 6666 = 8888 (3 suits)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "flower", amount: 2}))

			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 4}))
			newArr.push(createTiles({type: suitOrder[1], value: 4, amount: 4}))
			newArr.push(createTiles({type: suitOrder[2], value: 6, amount: 4}))
		})


		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "flower", amount: 2}))

			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 4}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 4}))
			newArr.push(createTiles({type: suitOrder[2], value: 8, amount: 4}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//22 444 66 888 DDDD (1 suit)
	function(tiles = []) {
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: suit, value: 2, amount: 2}))
			newArr.push(createTiles({type: suit, value: 4, amount: 3}))
			newArr.push(createTiles({type: suit, value: 6, amount: 2}))
			newArr.push(createTiles({type: suit, value: 8, amount: 3}))
			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suit], amount: 4}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FFFF 2468 222 222 (3 suits pongs like evens)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			evenOptions.forEach((num) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 4}))

				newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 1}))

				newArr.push(createTiles({type: suitOrder[1], value: num, amount: 3}))

				newArr.push(createTiles({type: suitOrder[2], value: num, amount: 3}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FFF 22 44 666 8888 (1 suit)
	function(tiles = []) {
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "flower", amount: 3}))

			newArr.push(createTiles({type: suit, value: 2, amount: 2}))
			newArr.push(createTiles({type: suit, value: 4, amount: 2}))
			newArr.push(createTiles({type: suit, value: 6, amount: 3}))
			newArr.push(createTiles({type: suit, value: 8, amount: 4}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//(222 4444 666) 88 88 (3 suits, pairs MUST BE 8s)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 3}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 4}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 3}))

			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 2}))

			newArr.push(createTiles({type: suitOrder[2], value: 8, amount: 2}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FF 2222 DDDD 2222 (3 suits, kongs like even)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			evenOptions.forEach((num) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: num, amount: 4}))
				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 4}))
				newArr.push(createTiles({type: suitOrder[2], value: num, amount: 4}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//22 44 66 88 222 222 (3 suits, concealed, pongs like even)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			evenOptions.forEach((num) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 2}))

				newArr.push(createTiles({type: suitOrder[1], value: num, amount: 3}))
				newArr.push(createTiles({type: suitOrder[2], value: num, amount: 3}))
			})
		})

		return {
			tiles,
			score: 30,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "2468"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

