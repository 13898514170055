const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//NN EW SS 11 22 33 44 (1 suit, consec, 50 points concealed)
	function(tiles = []) {
		allSuits.forEach((suit) => {
			allOptions.slice(0, -3).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "wind", value: "north", amount: 2}))
				newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "south", amount: 2}))

				newArr.push(createTiles({type: suit, value: offset + 0, amount: 2}))
				newArr.push(createTiles({type: suit, value: offset + 1, amount: 2}))
				newArr.push(createTiles({type: suit, value: offset + 2, amount: 2}))
				newArr.push(createTiles({type: suit, value: offset + 3, amount: 2}))
			})
		})

		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//FF (2468 DD) (2468 DD) (2 suits)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "flower", amount: 2}))

			newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 4, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 8, amount: 1}))
			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[0]], amount: 2}))

			newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 4, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 1}))
			newArr.push(createTiles({type: suitOrder[1], value: 8, amount: 1}))
			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 2}))
		})

		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//(33 66 99) (33 66 99) 33 - pair 3 6 or 9, 3 suits
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			;[3,6,9].forEach((num) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 6, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 2}))

				newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 2}))
				newArr.push(createTiles({type: suitOrder[1], value: 6, amount: 2}))
				newArr.push(createTiles({type: suitOrder[1], value: 9, amount: 2}))

				newArr.push(createTiles({type: suitOrder[2], value: num, amount: 2}))
			})
		})

		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//FF (11 22) (11 22) (11 22) (3 suits, any consec)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			allOptions.slice(0, -1).forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 2}))

				newArr.push(createTiles({type: suitOrder[0], value: offset + 0, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: offset + 1, amount: 2}))

				newArr.push(createTiles({type: suitOrder[1], value: offset + 0, amount: 2}))
				newArr.push(createTiles({type: suitOrder[1], value: offset + 1, amount: 2}))

				newArr.push(createTiles({type: suitOrder[2], value: offset + 0, amount: 2}))
				newArr.push(createTiles({type: suitOrder[2], value: offset + 1, amount: 2}))
			})
		})

		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//(11 33 55 77 99) (11) (11) (3 suits, any like odd pairs)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			oddOptions.forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 5, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 7, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 2}))

				newArr.push(createTiles({type: suitOrder[1], value: offset, amount: 2}))

				newArr.push(createTiles({type: suitOrder[2], value: offset, amount: 2}))
			})
		})

		return {
			tiles,
			score: 50,
			concealed: true
		}
	},
	//FF 2025 2025 2025 (3 suits, 75 points)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			oddOptions.forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 2}))

				newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 2, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 5, amount: 1}))

				newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 1}))

				newArr.push(createTiles({type: suitOrder[2], value: 2, amount: 1}))
				newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
				newArr.push(createTiles({type: suitOrder[2], value: 2, amount: 1}))
				newArr.push(createTiles({type: suitOrder[2], value: 5, amount: 1}))
			})
		})

		return {
			tiles,
			score: 75,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "Singles and Pairs"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

