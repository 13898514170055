//Alert for App Updates.
import {Capacitor} from "@capacitor/core";
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import {i18n} from "../../lib/i18nHelper.js";
import Popups from "./Popups";

/*
* Checks if an app update is available and prompts the user to update if so.
* @params none
* @returns boolean - true if an update prompt was shown, false otherwise
* */
async function promptIfUpdateAvailable() {
	let appUpdateInfo = await AppUpdate.getAppUpdateInfo();
	if (appUpdateInfo.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
		return false;
	}

	//TODO: Use AppUpdate.openAppStore()
	//TODO: How does this behave on Amazon devices???
	if (Capacitor.getPlatform() === "ios") {
		new Popups.Notification(i18n.__("App Update"), i18n.__("There is a Mahjong 4 Friends %s. Downloading it is recommended. You may experience issues if you do not update. ", "<a href='https://apps.apple.com/us/app/mahjong-4-friends/id1552704332' target='_blank'>" + i18n.__("app update") + "</a>")).show()
		return true
	}
	else if (Capacitor.getPlatform() === "android") {
		new Popups.Notification(i18n.__("App Update"), i18n.__("There is a Mahjong 4 Friends %s. Downloading it is recommended. You may experience issues if you do not update. ", "<a href='https://play.google.com/store/apps/details?id=com.mahjong4friends.twa' target='_blank'>" + i18n.__("app update") + "</a>")).show()
		return true
	}
}

export {promptIfUpdateAvailable}