const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations, allSuitArrangementsWithSingleSuits} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//(11 333) 5555 (777 99) (1 or 3 suits)
	function(tiles = []) {
		allSuitArrangementsWithSingleSuits.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 2}))
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 3}))
			newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 4}))
			newArr.push(createTiles({type: suitOrder[2], value: 7, amount: 3}))
			newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 2}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//(111 3333) (333 5555) or (555 7777) (777 9999)
	function(tiles = []) {
		;[1,5].forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[0], value: 2+offset, amount: 4}))

				newArr.push(createTiles({type: suitOrder[1], value: 2+offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: 4+offset, amount: 4}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//1111 333 5555 DDD or 5555 777 9999 DDD (1 suit)
	function(tiles = []) {
		;[1,5].forEach((offset) => {
			allSuits.forEach((suit) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: suit, value: 0+offset, amount: 4}))
				newArr.push(createTiles({type: suit, value: 2+offset, amount: 3}))
				newArr.push(createTiles({type: suit, value: 4+offset, amount: 4}))

				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suit], amount: 3}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FFFF 1111 + 9999 = (10) (2 suits)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "flower", amount: 4}))

			newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 4}))
			newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 4}))

			newArr.push(createTiles({type: suitOrder[1], value: 1, amount: 1}))
			newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FFF 135 7777 9999 (1 or 3 suits)
	function(tiles = []) {
		allSuitArrangementsWithSingleSuits.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "flower", amount: 3}))

			newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 3, amount: 1}))
			newArr.push(createTiles({type: suitOrder[0], value: 5, amount: 1}))

			newArr.push(createTiles({type: suitOrder[1], value: 7, amount: 4}))
			newArr.push(createTiles({type: suitOrder[2], value: 9, amount: 4}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//(111 333 5555) DD DD or (555 777 9999) DD DD (3 suits)
	function(tiles = []) {
		;[1,5].forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[0], value: 2+offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[0], value: 4+offset, amount: 4}))
				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 2}))
				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[2]], amount: 2}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//(11 333) NEWS (333 55) or (55 777) NEWS (777 99) (2 suits, 30 points)
	function(tiles = []) {
		;[1,5].forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 2+offset, amount: 3}))

				newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
				newArr.push(createTiles({type: "wind", value: "south", amount: 1}))

				newArr.push(createTiles({type: suitOrder[1], value: 2+offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: 4+offset, amount: 2}))
			})
		})

		return {
			tiles,
			score: 30,
			concealed: false
		}
	},
	//(1111) 33 55 77 (9999) (2 suits, 30 points)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 4}))

			newArr.push(createTiles({type: suitOrder[1], value: 3, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 5, amount: 2}))
			newArr.push(createTiles({type: suitOrder[1], value: 7, amount: 2}))

			newArr.push(createTiles({type: suitOrder[0], value: 9, amount: 4}))
		})

		return {
			tiles,
			score: 30,
			concealed: false
		}
	},
	//FF (11 33) (111 333) 55 or FF (55 77) (555 777) 99 (3 suits, 30 points, concealed)
	function(tiles = []) {
		;[1,5].forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 2}))

				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 2+offset, amount: 2}))

				newArr.push(createTiles({type: suitOrder[1], value: 0+offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: 2+offset, amount: 3}))

				newArr.push(createTiles({type: suitOrder[2], value: 4+offset, amount: 2}))
			})
		})

		return {
			tiles,
			score: 30,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "13579"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

