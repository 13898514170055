const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, allSuitArrangementsWithSingleSuits, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, combinations, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")


//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
		//FFF AA NNN GGG SSS - 25 points
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			//FFF
			newArr.push(createTiles({type: "flower", amount: 3}))

			//AA
			newArr.push(createTiles({type: "any", value: "any", amount: 2}))

			//NNN
			newArr.push(createTiles({type: "wind", value: "north", amount: 3}))

			//GGG
			newArr.push(createTiles({type: "dragon", value: "green", amount: 3}))

			//SSS
			newArr.push(createTiles({type: "wind", value: "south", amount: 3}))
		})

		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},
		//E KKKK A SNA KKKK E - 25 points
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			//E
			newArr.push(createTiles({type: "wind", value: "east", amount: 1}))

			//KKKK
			newArr.push(createTiles({type: "any", value: "any", amount: 4}))

			//A
			newArr.push(createTiles({type: "any", value: "any", amount: 1}))

			//SNA
			newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
			newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
			newArr.push(createTiles({type: "any", value: "any", amount: 1}))

			//KKKK
			newArr.push(createTiles({type: "any", value: "any", amount: 4}))

			//E
			newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
		})

		return {
			tiles,
			score: 25,
			concealed: false,
		}
	},

		//SLI PPP N SLI DDD E - 30 points
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			//SLI
			newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
			newArr.push(createTiles({type: "any", value: 7, amount: 1}))
			newArr.push(createTiles({type: "any", value: 1, amount: 1}))

			//PPP
			newArr.push(createTiles({type: "any", value: "any", amount: 3}))

			//N
			newArr.push(createTiles({type: "wind", value: "north", amount: 1}))

			//SLI
			newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
			newArr.push(createTiles({type: "any", value: 7, amount: 1}))
			newArr.push(createTiles({type: "any", value: 1, amount: 1}))

			//DDD
			newArr.push(createTiles({type: "dragon", value: "any", amount: 3}))

			//E
			newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
		})

		return {
			tiles,
			score: 30,
			concealed: false,
		}
	},
	//L II MMM B L EEE SSS - 30 points
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			//L
			newArr.push(createTiles({type: "any", value: 7, amount: 1}))

			//II
			newArr.push(createTiles({type: "any", value: 1, amount: 2}))

			//MMM
			newArr.push(createTiles({type: "wind", value: "west", amount: 3}))

			//B
			newArr.push(createTiles({type: "bamboo", value: "any", amount: 1}))

			//L
			newArr.push(createTiles({type: "any", value: 7, amount: 1}))

			//EEE
			newArr.push(createTiles({type: "wind", value: "east", amount: 3}))

			//SSS
			newArr.push(createTiles({type: "wind", value: "south", amount: 3}))
		})

		return {
			tiles,
			score: 30,
			concealed: false,
		}
	},
	//AA SN AA KKKK E BI2 - 35 points
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			//AA
			newArr.push(createTiles({type: "any", value: "any", amount: 2}))

			//SN
			newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
			newArr.push(createTiles({type: "wind", value: "north", amount: 1}))

			//AA
			newArr.push(createTiles({type: "any", value: "any", amount: 2}))

			//KKKK
			newArr.push(createTiles({type: "any", value: "any", amount: 4}))

			//E
			newArr.push(createTiles({type: "wind", value: "east", amount: 1}))

			//BI2
			newArr.push(createTiles({type: "bamboo", value: "any", amount: 1}))
			newArr.push(createTiles({type: "any", value: 1, amount: 1}))
			newArr.push(createTiles({type: "any", value: 2, amount: 1}))
		})

		return {
			tiles,
			score: 35,
			concealed: false,
		}
	},
	//SER PPP II N 22 INE - 1 or 2 suits - 40 points
	function(tiles = []) {
		allSuitArrangementsWithSingleSuits.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			//SER
			newArr.push(createTiles({type: "wind", value: "south", amount: 1}))
			newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
			newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))

			//PPP
			newArr.push(createTiles({type: "any", value: "any", amount: 3}))

			//II
			newArr.push(createTiles({type: suitOrder[0], value: 1, amount: 2}))

			//N
			newArr.push(createTiles({type: "wind", value: "north", amount: 1}))

			//22
			newArr.push(createTiles({type: suitOrder[1], value: 2, amount: 2}))

			//INE
			newArr.push(createTiles({type: "any", value: 1, amount: 1}))
			newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
			newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
		})

		return {
			tiles,
			score: 40,
			concealed: false,
		}
	},
	//A COLD KKKK I LL ER
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			//A
			newArr.push(createTiles({type: "any", value: "any", amount: 1}))

			//COLD
			newArr.push(createTiles({type: "character", value: "any", amount: 1}))
			newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
			newArr.push(createTiles({type: "any", value: 7, amount: 1}))
			newArr.push(createTiles({type: "dragon", value: "any", amount: 1}))

			//KKKK
			newArr.push(createTiles({type: "any", value: "any", amount: 4}))

			//I
			newArr.push(createTiles({type: "any", value: 1, amount: 1}))

			//LL
			newArr.push(createTiles({type: "any", value: 7, amount: 2}))

			//ER
			newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
			newArr.push(createTiles({type: "dragon", value: "red", amount: 1}))
		})

		return {
			tiles,
			score: 40,
			concealed: false,
		}
	},

].map((func, index) => {
	let output = func()
	output.section = "Dead Ringer"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

