import {hasAdFree} from "../ownedProducts";

import {adPlatform} from "./platformSelector"
const {initialize, prepareInterstitial, displayInterstitial} = adPlatform

import {InAppReview} from "@capacitor-community/in-app-review";

//The interstitial free period for new users is the setting default for settings.msUntilNextInterstitial

let msInterstitialFreeDaily = 15 * 60 * 1000 //No interstitials for first 15 minutes daily. 
let msBetweenInterstitials = 20 * 60 * 1000 //No ads until 20 minutes after previous interstitial.


function awardInterstitialFreeMinutes() {
    let priorDate = (new Date(window.settings.lastReceivedInterstitialMinutesTimestamp.value)).toDateString()
    let currentDate = (new Date()).toDateString()
    if (priorDate !== currentDate) {
        window.settings.msUntilNextInterstitial.value = Math.max(window.settings.msUntilNextInterstitial.value, msInterstitialFreeDaily)
        window.settings.lastReceivedInterstitialMinutesTimestamp.value = Date.now()
    }

    //Determine timer duration for next day. 
    let nextDay = new Date(`1/1/2021`) //Arbitrary day - we'll update all the values.
    nextDay.setDate(new Date().getDate() + 1)
    nextDay.setMonth(new Date().getMonth())
    nextDay.setYear(new Date().getFullYear())

    setTimeout(awardInterstitialFreeMinutes, nextDay.getTime() - Date.now())
}

Promise.allSettled([
    window.settings.msUntilNextInterstitial.loaded,
    window.settings.lastReceivedInterstitialMinutesTimestamp.loaded
]).then(() => {
    awardInterstitialFreeMinutes()

    let checkGameplayStateInterval = 20 * 1000 //How frequently we'll adjust msUntilNextInterstitial
    setInterval(function() {
        //We could use Capacitor.Plugins.App.getState() on native, but that doesn't appear necessary. 
        if (document.hasFocus()) {
            //Only count time when user is in a game and game is not over. 
            if (stateManager?.lastState?.message?.inGame && !stateManager?.lastState?.message?.isGameOver) {
                window.settings.msUntilNextInterstitial.value -= checkGameplayStateInterval
            }
        }
    }, checkGameplayStateInterval)

    //Activate ad preloading. 
    //Let's preload ads 30 seconds before they are needed. 
    let preloadBuffer = 30 * 1000
    let mostRecentPreloadTimeout;
    window.settings.msUntilNextInterstitial.addOnValueSetListener(function() {
        clearTimeout(mostRecentPreloadTimeout)

        mostRecentPreloadTimeout = setTimeout(prepareInterstitial, window.settings.msUntilNextInterstitial.value - preloadBuffer)
    })
})





let lastIsGameOver; //Detect when game state changes.

async function stateUpdateListener(state) {
    //Display ads when game ends.
    let isGameOver = state?.message?.isGameOver

    if (isGameOver > 0 && lastIsGameOver === 0) {
        //Game has ended.
        console.log("Intersitial Point Reached")
        lastIsGameOver = isGameOver

        window?.FirebaseAnalytics?.logEvent?.({
            name: "interstitialPoint",
            params: {}
        });


        if (
            window?.stateManager?.lastState?.message?.details?.exemptFromAds //Game exempt from ads (room started by premium member).
            || await hasAdFree() //Purchased ad-free for this variant
            || window.settings.msUntilNextInterstitial.value > 0 //User has not yet reached interstitial activiation time. 
        ) {
            //The user does not qualify to see an interstitial.
            //Add a one-time event listener to call the rating prompt when the game is ended IF this user went Mahjong.
            if (
                window?.stateManager.lastState?.message?.currentTurn?.userTurn === window.stateManager.connectedClientId
                && window?.stateManager.lastState?.message?.isGameOver === 1 //Mahjong only, not wall empty.
            ) {
                stateManager.addEventListener("endGame", function() {
                    //We will only prompt repeat users (>25 app opens)
                    if (window.settings.timesAppOpened.value > 25) {
                        InAppReview.requestReview();
                    }
                }, {once: true})
            }
        }
        else {
            //The user qualifies to see an interstitial.
            let wasShown = displayInterstitial()
            if (wasShown) {
                window.settings.msUntilNextInterstitial.value = msBetweenInterstitials
                window.settings.adsShown.value++
            }
            else {
                console.warn("Failed to Show Interstitial (wasShown false)")
            }
        }
    }
    else {
        lastIsGameOver = isGameOver
    }
}

function enableInterstitialAds() {
    window.stateManager.addEventListener("state", stateUpdateListener)
}

export {enableInterstitialAds}
