const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js");

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//FF (111) (2222) (33333) (40 points consec 3 suits)
	function(tiles = []) {
		allOptions.slice(0, -2).forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 2}))

				newArr.push(createTiles({type: suitOrder[0], value: 0 + offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: 1 + offset, amount: 4}))
				newArr.push(createTiles({type: suitOrder[2], value: 2 + offset, amount: 5}))
			})
		})

		return {
			tiles,
			score: 40,
			concealed: false
		}
	},
	//11111 NNNN 22222 (1 suit, consec, any wind, 45 points)
	function(tiles = []) {
		allOptions.slice(0, -1).forEach((offset) => {
			allSuits.forEach((suit) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: suit, value: 0 + offset, amount: 5}))
				newArr.push(createTiles({type: "wind", value: "any", amount: 4}))
				newArr.push(createTiles({type: suit, value: 1 + offset, amount: 5}))
			})
		})

		return {
			tiles,
			score: 45,
			concealed: false
		}
	},
	//FF 11111 11 11111 (3 suits like nums, 45 points)
	function(tiles = []) {
		allOptions.forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 2}))

				newArr.push(createTiles({type: suitOrder[0], value: 0 + offset, amount: 5}))
				newArr.push(createTiles({type: suitOrder[1], value: 0 + offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[2], value: 0 + offset, amount: 5}))
			})
		})

		return {
			tiles,
			score: 45,
			concealed: false
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "Quints"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

