const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations, allSuitArrangementsWithSingleSuits} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//11 222 3333 444 55 or 55 666 7777 888 99
	function(tiles = []) {
		;[1, 5].forEach((offset) => {
			allSuits.forEach((suit) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: suit, value: 0+offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 1+offset, amount: 3}))
				newArr.push(createTiles({type: suit, value: 2+offset, amount: 4}))
				newArr.push(createTiles({type: suit, value: 3+offset, amount: 3}))
				newArr.push(createTiles({type: suit, value: 4+offset, amount: 2}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//111 2222 333 4444 or (111 2222) (333 4444) - 1 or 2 suits
	function(tiles = []) {
		allOptions.slice(0, -3).forEach((offset) => {
			allSuitArrangementsWithSingleSuits.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[0], value: 1+offset, amount: 4}))
				newArr.push(createTiles({type: suitOrder[1], value: 2+offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[1], value: 3+offset, amount: 4}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FFFF 1111 22 3333 (1 or 3 suits)
	function(tiles = []) {
		allOptions.slice(0, -2).forEach((offset) => {
			allSuitArrangementsWithSingleSuits.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 4}))

				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 4}))
				newArr.push(createTiles({type: suitOrder[1], value: 1+offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[2], value: 2+offset, amount: 4}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FFF 123 4444 5555 (3 suits)
	function(tiles = []) {
		allOptions.slice(0, -4).forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 3}))

				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 1+offset, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 2+offset, amount: 1}))

				newArr.push(createTiles({type: suitOrder[1], value: 3+offset, amount: 4}))

				newArr.push(createTiles({type: suitOrder[2], value: 4+offset, amount: 4}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FF 11 222 3333 DDD (1 suit)
	function(tiles = []) {
		allOptions.slice(0, -2).forEach((offset) => {
			allSuits.forEach((suit) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 2}))

				newArr.push(createTiles({type: suit, value: 0+offset, amount: 2}))
				newArr.push(createTiles({type: suit, value: 1+offset, amount: 3}))
				newArr.push(createTiles({type: suit, value: 2+offset, amount: 4}))
				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suit], amount: 3}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//(111 222 3333) DD DD (3 suits, opposite dragons)
	function(tiles = []) {
		allOptions.slice(0, -2).forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[0], value: 1+offset, amount: 3}))
				newArr.push(createTiles({type: suitOrder[0], value: 2+offset, amount: 4}))

				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 2}))

				newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[2]], amount: 2}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//112345 1111 1111 (3 suits, kongs match pair num, pair any no in run, 30 points)
	function(tiles = []) {
		allOptions.slice(0, -4).forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				;[0,1,2,3,4].forEach((kongNumOffset) => {
					let newArr = []
					tiles.push(newArr)

					newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: (0 === kongNumOffset ? 2 : 1)}))
					newArr.push(createTiles({type: suitOrder[0], value: 1+offset, amount: (1 === kongNumOffset ? 2 : 1)}))
					newArr.push(createTiles({type: suitOrder[0], value: 2+offset, amount: (2 === kongNumOffset ? 2 : 1)}))
					newArr.push(createTiles({type: suitOrder[0], value: 3+offset, amount: (3 === kongNumOffset ? 2 : 1)}))
					newArr.push(createTiles({type: suitOrder[0], value: 4+offset, amount: (4 === kongNumOffset ? 2 : 1)}))

					newArr.push(createTiles({type: suitOrder[1], value: kongNumOffset + offset, amount: 4}))

					newArr.push(createTiles({type: suitOrder[2], value: kongNumOffset + offset, amount: 4}))
				})
			})
		})

		return {
			tiles,
			score: 30,
			concealed: false
		}
	},
	//FF (1 22 333) (1 22 333) (2 suits, 30 points, concealed)
	function(tiles = []) {
		allOptions.slice(0, -2).forEach((offset) => {
			allSuitArrangements.forEach((suitOrder) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "flower", amount: 2}))

				newArr.push(createTiles({type: suitOrder[0], value: 0+offset, amount: 1}))
				newArr.push(createTiles({type: suitOrder[0], value: 1+offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[0], value: 2+offset, amount: 3}))

				newArr.push(createTiles({type: suitOrder[1], value: 0+offset, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: 1+offset, amount: 2}))
				newArr.push(createTiles({type: suitOrder[1], value: 2+offset, amount: 3}))
			})
		})

		return {
			tiles,
			score: 30,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "Consecutive Run"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

