const CardHand = require("../CardHand.js")
const {getPairs, allTiles, nonJokerTiles, createTiles, allSuits, allSuitArrangements, oddOptions, evenOptions, allOptions, windOptions, windArrangments, dragonOptions, dragonArrangments, suitDragonConversion, getTileDifferential, permutations} = require("../utilities/index.js")

//Each function will return an array. Each array will contain every possible matching combo in the form of an array of tiles.
module.exports = [
	//NNNN EEE WWW SSSS or NNN EEEE WWWW SSS
	function(tiles = []) {
		;[0].forEach((num) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "wind", value: "north", amount: 4}))
			newArr.push(createTiles({type: "wind", value: "east", amount: 3}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 3}))
			newArr.push(createTiles({type: "wind", value: "south", amount: 4}))
		})

		;[0].forEach((num) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "wind", value: "north", amount: 3}))
			newArr.push(createTiles({type: "wind", value: "east", amount: 4}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 4}))
			newArr.push(createTiles({type: "wind", value: "south", amount: 3}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FF 123 DD DDD DDDD  (3 consec nums in 1 suit)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			allSuits.forEach((suitForRun) => {
				allOptions.slice(0,-2).forEach((offset) => {
					let newArr = []
					tiles.push(newArr)

					newArr.push(createTiles({type: "flower", amount: 2}))

					newArr.push(createTiles({type: suitForRun, value: offset + 0, amount: 1}))
					newArr.push(createTiles({type: suitForRun, value: offset + 1, amount: 1}))
					newArr.push(createTiles({type: suitForRun, value: offset + 2, amount: 1}))

					newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[0]], amount: 2}))
					newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 3}))
					newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[2]], amount: 4}))
				})
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FFF NN EE WWW SSSS
	function(tiles = []) {
		let newArr = []
		tiles.push(newArr)

		newArr.push(createTiles({type: "flower", amount: 3}))

		newArr.push(createTiles({type: "wind", value: "north", amount: 2}))
		newArr.push(createTiles({type: "wind", value: "east", amount: 2}))
		newArr.push(createTiles({type: "wind", value: "west", amount: 3}))
		newArr.push(createTiles({type: "wind", value: "south", amount: 4}))

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//FFFF DDD NEWS DDD (drags different suits)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "flower", amount: 4}))

			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[0]], amount: 3}))

			newArr.push(createTiles({type: "wind", value: "north", amount: 1}))
			newArr.push(createTiles({type: "wind", value: "east", amount: 1}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 1}))
			newArr.push(createTiles({type: "wind", value: "south", amount: 1}))

			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suitOrder[1]], amount: 3}))
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//NNNN 1 11 111 SSSS (any like odd nos, 3 suits)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			oddOptions.forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "wind", value: "north", amount: 4}))

				newArr.push(createTiles({type: suitOrder[0], value: offset + 0, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: offset + 0, amount: 2}))
				newArr.push(createTiles({type: suitOrder[2], value: offset + 0, amount: 3}))

				newArr.push(createTiles({type: "wind", value: "south", amount: 4}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//EEEE 2 22 222 WWWW (like evens, 3 suits)
	function(tiles = []) {
		allSuitArrangements.forEach((suitOrder) => {
			evenOptions.forEach((offset) => {
				let newArr = []
				tiles.push(newArr)

				newArr.push(createTiles({type: "wind", value: "east", amount: 4}))

				newArr.push(createTiles({type: suitOrder[0], value: offset + 0, amount: 1}))
				newArr.push(createTiles({type: suitOrder[1], value: offset + 0, amount: 2}))
				newArr.push(createTiles({type: suitOrder[2], value: offset + 0, amount: 3}))

				newArr.push(createTiles({type: "wind", value: "west", amount: 4}))
			})
		})

		return {
			tiles,
			score: 25,
			concealed: false
		}
	},
	//NN EEE WWW SS 2025 or NNN EE WW SSS 2025 (1 suit, 30 points)
	function(tiles = []) {
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "wind", value: "north", amount: 2}))
			newArr.push(createTiles({type: "wind", value: "east", amount: 3}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 3}))
			newArr.push(createTiles({type: "wind", value: "south", amount: 2}))

			newArr.push(createTiles({type: suit, value: 2, amount: 1}))
			newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
			newArr.push(createTiles({type: suit, value: 2, amount: 1}))
			newArr.push(createTiles({type: suit, value: 5, amount: 1}))
		})

		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "wind", value: "north", amount: 3}))
			newArr.push(createTiles({type: "wind", value: "east", amount: 2}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 2}))
			newArr.push(createTiles({type: "wind", value: "south", amount: 3}))

			newArr.push(createTiles({type: suit, value: 2, amount: 1}))
			newArr.push(createTiles({type: "dragon", value: "white", amount: 1}))
			newArr.push(createTiles({type: suit, value: 2, amount: 1}))
			newArr.push(createTiles({type: suit, value: 5, amount: 1}))
		})

		return {
			tiles,
			score: 30,
			concealed: false
		}
	},
	//NN EE WWW SSS DDDD (30 points, concealed)
	function(tiles = []) {
		allSuits.forEach((suit) => {
			let newArr = []
			tiles.push(newArr)

			newArr.push(createTiles({type: "wind", value: "north", amount: 2}))
			newArr.push(createTiles({type: "wind", value: "east", amount: 2}))
			newArr.push(createTiles({type: "wind", value: "west", amount: 3}))
			newArr.push(createTiles({type: "wind", value: "south", amount: 3}))

			newArr.push(createTiles({type: "dragon", value: suitDragonConversion[suit], amount: 4}))
		})

		return {
			tiles,
			score: 30,
			concealed: true
		}
	},
].map((func, index) => {
	let output = func()
	output.section = "Winds and Dragons"
	output.sectionIndex = index + 1 //Sections indexed starting at 1. 
	output.handName = `${output.section} #${output.sectionIndex}`
	return new CardHand(output)
})

