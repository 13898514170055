let cards = {
	"2020 National Mah Jongg League": require("./nmjl2020/index.js"),
	"2021 National Mah Jongg League": require("./nmjl2021/index.js"),
	"2022 National Mah Jongg League": require("./nmjl2022/index.js"),
	"2023 National Mah Jongg League": require("./nmjl2023/index.js"),
	"2024 National Mah Jongg League": require("./nmjl2024/index.js"),
	"2025 National Mah Jongg League": require("./nmjl2025/index.js"),
}

let cardNames = Object.keys(cards) //Bots will not pick Marvelous as a random card.

cards["2021 Marvelous Mah Jongg"] =  require("./marvelous2021/index.js")
cards["2022 Marvelous Mah Jongg"] =  require("./marvelous2022/index.js")
cards["2023 Marvelous Mah Jongg"] =  require("./marvelous2023/index.js")
cards["2024 Marvelous Mah Jongg"] =  require("./marvelous2024/index.js")
cards["2025 Marvelous Mah Jongg"] =  require("./marvelous2025/index.js")

function getRandomCard() {
	return cards[cardNames[Math.floor(Math.random() * cardNames.length)]]
}

Object.defineProperty(cards, 'Random', {
	get: getRandomCard
});

module.exports = cards
